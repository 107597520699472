:root {
  scrollbar-color: var(--color-primary) white;
  scrollbar-width: thin;
}

:root::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #cfcfcf;
}

:root::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.2);
  background-color: var(--white);
}

:root::-webkit-scrollbar-thumb {
  border-radius: 3rem;
  background-color: var(--white);
  background-image: -webkit-gradient(
    linear,
    0% 0%,
    50% 100%,
    from(#af006a),
    color-stop(0.5, #af006a),
    to(#af006a)
  );
}

.wholepage {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
}

body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
ul,
li,
a,
section,
form,
table,
td,
tr,
tbody,
select,
label,
figure {
  margin: 0;
  padding: 0;
}

body {
  font-size: 15px;
}

.main-content {
  background: #f0efec;
  min-height: calc(100vh - 81px);
  display: flex;
  flex-direction: column;
}

header {
  position: relative;
  width: 100%;
  z-index: 3;
}

.content_section {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-grow: 1;
}

.page-content {
  width: 100%;
  flex: 0 0 100%;
  padding-top: 42px;
  padding-right: 60px;
  padding-left: 35px;
  padding-bottom: 50px;
  background-color: #f0efec;
  color: #4d4f5c;
}

.no-pad-top {
  padding-top: 0;
}

.page-content h2 {
  color: #004560;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.page-content > p {
  font-weight: 400;
  color: #4d4f5c;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

h2 > .spinner-border {
  position: absolute;
  margin: -5px 0 0 10px;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.table-cell {
  background: white !important;
  color: black !important;
}

.table-cell-header {
  font-weight: bold;
  background: whitesmoke;
  color: black !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.graph_btns {
  display: flex;
  align-items: baseline;
  gap: 0.5em;
}

.download_spreadsheet {
  background-color: #004560;
  margin-left: 1em;
}

.download_spreadsheet:hover,
.download_spreadsheet:active,
.download_spreadsheet:focus {
  background-color: #00577e;
}

.download_spreadsheet i {
  margin-right: 5px;
}
