.installment_row {
  margin-bottom: 50px;
}
.graph_section {
  padding-top: 1em;
}
.graph_wrapper {
  display: flex;
  flex-wrap: wrap;
}
.installment_graph {
  background: #fff;
  flex: 0 0 calc(55% - 10px);
  max-width: calc(55% - 10px);
}
.installment_graph img {
  width: 100%;
  object-position: center;
  object-fit: cover;
  max-height: 500px;
}
.volume_container h3 {
  color: #4d4f5c;
  font-weight: 400;
  margin-bottom: 20px;
}
.volume_table {
  background: #fff;
  padding: 23px 20px 16px;
  flex: 0 0 calc(45% - 10px);
  max-width: calc(45% - 10px);
  margin-left: 20px;
}
.volume_container a,
.cohorttbl_data a {
  color: #3b86ff;
  font-size: 13px;
  display: block;
  text-align: center;
  padding-top: 20px;
}
.volume_table_data {
  margin-top: 20px;
  height: 400px;
  overflow: auto;
}
.volume_table_data th div {
  position: absolute;
  margin-top: -20px;
}
.overall_table_container {
  width: 100%;
  margin-bottom: 50px;
}
.overall_table_data {
  width: 100%;
}
.cohorttbl_data {
  overflow-y: scroll;
  width: 100%;
  height: 350px;
}
