.footer_menu {
    margin-top: 5px;
}
.footer_menu li {
    display: inline-block;
}
.footer_container {
    background: #1C1C1C;
    color: #fff;
    text-align: center;
    font-size: 12px;
    padding: 20px;
}
ul > li, li {
    list-style: none outside none;
    cursor: pointer;
}
.footer_menu li + li::before {
    content: "|";
    display: inline-block;
    font-size: 11px;
    margin-left: 3px;
    margin-right: 5px;
}