.filter-wrapper {
  padding-right: 1em;
  padding-left: 1em;
  padding-bottom: 1em;
}

.filter-card {
  margin: 2em auto;
  min-width: 400px;
}

.filter-content {
  min-height: 100%;
  overflow: hidden;
}

.context-title {
  color: #004560;
  font-size: 18px;
  font-weight: 600;
}

.context-footer {
  font-weight: 400;
  color: #4d4f5c;
}

.filter-card-apply-filters-btn {
  display: flex;
  flex-direction: row-reverse;
}
