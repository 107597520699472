.dd-parent {
    display: block;
    width: fit-content;
}

.dd-list {
    opacity: 1;
    z-index: 2;
    -webkit-animation: fadein 0.25s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.25s; /* Firefox < 16 */
    -ms-animation: fadein 0.25s; /* Internet Explorer */
    -o-animation: fadein 0.25s; /* Opera < 12.1 */
    animation: fadein 0.25s;
}

.dd-list {
    position: absolute;
    min-width: 5em;
    max-width: 50rem;
    max-height: 29.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    margin-top: 0.25em;
    border-width: 1px;
    border-color: lightgray;
    border-style: solid;
    border-radius: 5px;
}

.dd-list::-webkit-scrollbar {
    width: 6px;
}

.dd-list {
    scrollbar-width: thin;
    scrollbar-color: #90A4AE;
}

.dd-list::-webkit-scrollbar-track {
    z-index: 2;
    background: #f9fafb;
}

.dd-list {
    background: #f9fafb;
}

.dd-list::-webkit-scrollbar-thumb {

    background-color: #90A4AE;
    border-radius: 3px;
    border: 3px solid rgba(207, 216, 220, 0);
}

.dd-list div {
    padding-right: 1em;
}

.dd-list div:first-child {
    padding-top: 1.6rem;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.dd-list div:last-child {
    padding-bottom: .6rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dd-text {
    white-space: nowrap;
}

.clear-button:focus {
    outline: 0;
}