.sidebar-filter-button {
    width: 100%;
    font-size: medium;
    justify-content: left;
    margin-bottom: 1em;
}

.sidebar-filter-hr {
    margin-top: 1em;
    margin-bottom: 1em;
}

.sidebar-content > label {
    width: 300px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: inline-block;
}

.sidebar-filter-wrapper {
    margin-bottom: 0.5em;
}

.sidebar-filter-tag {
    top: -1em;
}

.sidebar-filter-clear {
    color: #065aef;
}

.sidebar-filter-clear:hover {
    text-decoration: underline;
}

.sidebar-list {
    -webkit-animation: fadein 0.35s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.35s; /* Firefox < 16 */
    -ms-animation: fadein 0.35s; /* Internet Explorer */
    -o-animation: fadein 0.35s; /* Opera < 12.1 */
    animation: fadein 0.35s;
}
