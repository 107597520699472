.help_container {

    max-width: 1140px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
    padding-top: 40px;

}
.help_container h2 {
    font-size: 18px;
    color: #004560;
    font-weight: 700;
}
.help_wrapper {
    max-width: 630px;
    min-width: 630px;
    margin: 0 auto;
    display: block;
}
.search_bar {
    position: relative;
    margin: 25px 0;
}
.search_bar input {
    height: 50px;
    width: 100%;
    line-height: 50px;
    border-radius: 50px;
    border: none;
    padding: 0 35px;
    outline: none;
}
.search_bar img {
    position: absolute;
    top: 0;
    right: 20px;
    width: 16px;
    line-height: 45px;
    display: inline-block;
    height: 45px;
    object-fit: contain;
    object-position: center;
}
.help_filter {
    border-bottom: 1px solid #D8D8D8;
}
.help_filter span {
    color: #004560;
    font-weight: 400;
}
.help_tab {
    display: flex;
    margin-top: 10px;
    margin-bottom: 35px;
}
ul > li, li {
    list-style: none;
    cursor: pointer;
}
.help_tab .self_btn.active {
    background: #939393;
    color: #fff;
}
.q_and_a {
    padding-bottom: 15px;
}
.question_q i {
    font-size: 22px;
    color: #333333;
}
.qa_section {
    margin-top: 35px;
}
.question_q i {
    font-size: 22px;
    color: #333333;
}
.question_q .grey_btn {
    height: 20px;
    line-height: 20px;
    vertical-align: text-bottom;
    margin-left: 2px;
}
.question_q a {
    font-size: 8px;
    margin-left: 2px;
    margin-right: 2px;
}
.grey_btn {
    background: #939393;
}
.answer_a h4 {
    color: #333333;
    margin-bottom: 10px;
}