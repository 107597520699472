.forecastActions{
    position: absolute; right: 0;
    margin-right: 10px;
}
.list-group-item.active{
    outline: none;
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
    background: rgba(255, 255, 255, 0);
    color: #4D4F5C;
}