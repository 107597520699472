.row1 {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 20px;
  margin-bottom: 1em;
  justify-content: space-between;
}

.aa_section {
  margin-top: 1.5em;
}

.aa_section .row1 {
  margin-left: 0px;
  margin-bottom: 0px;
  height: 477.5px;
}

.aa_section > span {
  color: #004560;
  font-weight: 600;
}

.aa_wrapper .aa_rt_wrapper {
  flex: 0 0 40.6%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-left: 6px;
}

.aa_rt_wrapper .kpi_container_ {
  flex: 0 0 50%;
  max-height: 152.5px !important;
}

.aa_wrapper > .chart-section {
  flex: 0 0 59.2%;
  max-width: 59.2%;
  height: 477.5px;
  padding-right: 10px;
}

.aa_wrapper > .chart-section > .chart-container {
  width: 100%;
  height: 100%;
  background-color: white;
}
