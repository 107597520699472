.sp4_box {
    display: flex;
    flex-wrap: wrap;
}
.vi_section {
    padding: 50px;
}
.vi_section > h2 {
    color: #004560;
}
.vi_part {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 20px;
}
.vibtn_wrap {
    text-align: center!important;
    margin-top: 20px;
}