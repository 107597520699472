.dataq_section {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 30px;
}
.dataq_mcontainer {
    flex: 0 0 66%;
    max-width: 66%;
    padding-left: 10px;
    padding-right: 10px;
}
.dataq_mcontainer.uniqueness {
    flex: 0 0 33%;
    max-width: 33%;
}
.dataq_container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}
.dataq_container > .data_q {
    margin-bottom: 40px;
}
.dataq_row {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
}
.dataq_row.uniqueness {
    flex: 0 0 100%;
    max-width: 100%;
}
.data_q {
    max-width: 100%;
    flex: 0 0 100%;
    display: flex;
    justify-content: space-between;
}
.data_q .dataq_mcontent {
    width: 49%;
}
.dataq_mcontent > h3 {
    color: #004560;
    font-weight: 400;
    margin-bottom: 10px;
}
