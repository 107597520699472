.inviteCard {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
.alertDiv {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.expirationInput {
  width: 100%;
}
#blackLabel {
  color: black;
}
.rightButton {
  position: absolute;
  right: 0;
  margin-right: 10px;
}
.memberListItem {
  width: 100%;
}

.flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flexColumn {
  flex-direction: column;
}

.flexRow {
  flex-direction: row;
}

.cardHeader {
  min-height: 50px;
  align-items: center;
}

.emailMinWidth {
  min-width: 350px;
}

.inviteCard label {
  margin-right: 1em;
}
