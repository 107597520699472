.newforecast_container {
    max-width: 1140px;
    margin: 0 auto;
}
.newforecast_container {
    max-width: 950px;
}
.new_toop {
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
}
.new_toop > h3 {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.set_favv {
    margin-left: 5px;
}
.newforecast_container i .favourite{
    color: #FFCA3A;
}
.icon-favourites::before {
    content: "\e901";
}
::before, ::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.newf_container > h3 {
    margin: 30px 0;
    color: #004560;
    font-weight: 400;
    font-size: 18px;
}
.newf_row {
    display: flex;
    justify-content: space-between;
}
.newfgraphb_section {
    background: #004560;
    padding: 35px;
}
.newfgraph_section {
    max-width: 70%;
    flex: 0 0 70%;
    background: white;
}
.newfgraph_img {
    background: #fff;
}
.newfgraphb_section {
    max-width: 30%;
    flex: 0 0 30%;
    margin-left: 20px;
}
.totalp_section {
    position: relative;
}
.totalp_top {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    width: 185px;
    height: 185px;
    border-radius: 100%;
    border: 3px solid #0090C7;
    margin-bottom: 30px;

}
.totalp_top h4 {
    font-size: 35px;
    color: #fff;
    font-weight: 400;
    line-height: 35px;
    text-align: center;
}
.totalp_top h5, .totalp_top span {
    color: #fff;
}
.p_red_sec {
    right: 8px;
}
.p_red_sec {
    position: absolute;
    top: -18px;
    right: -26px;
    background: #9D0707;
    padding: 14px;
    color: #fff;
    opacity: 0.6;
    text-align: center;
}
.p_red_sec h3 {
    font-size: 12px;
    font-weight: 300;
}
.p_red_sec span {
    font-size: 17px;
    font-weight: 700;
}
.totalp_bot {
    display: flex;
}
.totalp_bot span {
    color: #fff;
    font-size: 8px;
    font-weight: 300;
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.totalp_bot.empty span {
    font-size: 18px;
    font-weight: 400;
    margin-left: 5px;
}
.totalp_bot h3 {
    margin-left: 10px;
    font-size: 17.55px;
    color: #fff;
}
.totalp_bot span {
    padding: 10px;
}
.totalp_bot span {
    color: #fff;
    font-size: 8px;
    font-weight: 300;
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.download_btns {
    margin-top: 35px;
    text-align: center;
}
.darkblue_btn {
    background: #004560;
    border-radius: 5px;
    border: 2px solid rgba(0, 170, 102, 0);
}
.download_btns i {
    object-position: center;
    object-fit: cover;
    padding-right: 5px;
}
.lblue_btn {
    border-radius: 5px;
    margin-left: 15px;
    border: 2px solid rgba(0, 170, 102, 0);
    background: #00ACEF;
}
.remain_donor_section > h4 {
    font-size: 15px;
    font-weight: 600;
    color: #004560;
    margin-bottom: 25px;
}
.totalp_bot img {
    object-position: center;
}
.totalp_bot span {
    position: absolute;
    font-size: 8px;
    text-align: center;
}

.selected-forecasts-header {
    display: flex;
    flex-direction: row;
    color: #4D4F5C;
    font-size: 15px;
    line-height: 23px;
    font-weight: 400;
    align-items: center;
}

.selected-forecast-group {
    margin-left: auto;
    margin-right: 10px;
}

.results-list-group {
    display: flex;
    flex-direction: column;
}

.results-list-row {
    display: flex;
    flex-direction: row;
}

.results-list-row > b {
    font-size: 15px;
    line-height: 23px;
    font-weight: 700;
    align-items: center;
    color: #4D4F5C;
    margin-right: 5px;
}

.results-list-row > p {
    font-size: 15px;
    color: #4D4F5C;
    line-height: 23px;
}

.forecast-badge {
    margin-left: 10px;
}