.each_spbox_wrapper {
    position: relative;
    padding: 40px;
}
.sp_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sp_header i {
    font-size: 25px;
    color: #004560;
}
.sp_body h2::after {
    content: "";
    display: block;
    width: 80px;
    height: 5px;
    background: #FFCA3A;
    margin: 10px auto;
}
.each_spbox {
    width: 50%;
    flex: 0 0 50%;
    position: relative;
    z-index: 9;
}
.sp_body {
    padding: 40px 0;
    text-align: center;
    font-size: 20px;
}
.sp_body a {
    margin-top: 30px;
    display: inline-block;
    color: #00ACEF;
}
[class^="icon-"], [class*=" icon-"] {
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
}
.self_btn {
    display: inline-block;
    height: 42px;
    line-height: 42px;
    background: #939393;
    color: #fff;
    padding: 0 20px;
    border-radius: 42px;
    text-align: center;
    position: relative;
}
.lblue_btns {
    background: #00ACEF;
}
.each_spbox figure::before {
    background: #D7F6FF;
    content: '';
}
.each_spbox:nth-child(2) figure::before, .each_spbox:nth-child(3) figure::before {
    background: #004560;
}
.each_spbox:nth-child(2) .sp_header i, .each_spbox:nth-child(3) .sp_header i, .each_spbox:nth-child(2) .sp_body h2, .each_spbox:nth-child(3) .sp_body h2, .each_spbox:nth-child(2) .sp_body, .each_spbox:nth-child(3) .sp_body {
    color: #fff;
}
.each_spbox figure, .each_spbox figure::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.each_spbox figure img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}