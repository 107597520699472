.import-form-section {
    background: #004560;
    min-height: 30vh;
}

.importdata_container {
    background: #004560;
    margin: 0 auto;
    padding: 40px 15px 30px;
    max-width: 780px;
}

.importdata_container > h2 {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}

.importdata_container h3 {
    font-size: 16px;
    color: #004560;
    font-weight: 700;
}

.importdata_wrapper {
    max-width: 630px;
    min-width: 630px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.import-right_form {
    border-left: 1px solid #fff;
    width: 100%;
    padding-left: 40px;
}

.import-right_form .form-group {

}

.import-left_form {
    margin-bottom: 15px;
}

.import-form-wrapper {
    display: flex;
}

.input-left-form-g {
    flex: 0 0 29%;
    max-width: 29%;
    padding-right: 40px;
}

.input-left-form-g .form-group {
    width: 100%;
}

.etl-container {
    min-height: 60vh;
    margin: 0 auto;
    padding: 40px 15px 30px;
    max-width: 780px;
}

.import-left_form > p {
    color: white;
    margin: 1em 0;
}

.import-right_form {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}

.w-import-right_form {
    width: 80%;
}

.etl-component{
    margin: 1em 0;
}