.bp3-drawer-body::-webkit-scrollbar-track {
    background: rgba(207, 216, 220, 0) !important;
}

.bp3-drawer-body::-webkit-scrollbar-thumb {
    background-color: #90A4AE !important;
    border-radius: 3px !important;
    border: 3px solid rgba(207, 216, 220, 0) !important;
}

.bp3-drawer-body{
    overflow-y: auto;
}

.bp3-drawer-body::-webkit-scrollbar {
    width: 6px;
}

.bp3-drawer-body {
    scrollbar-width: thin;
    scrollbar-color: #90A4AE;
}

.bp3-drawer-body::-webkit-scrollbar-track {
    background: rgba(207, 216, 220, 0);
}

.bp3-drawer-body::-webkit-scrollbar-thumb {
    background-color: #90A4AE;
    border-radius: 3px;
    border: 3px solid rgba(207, 216, 220, 0);
}