.dataq_content {
	background: #fff;
	margin-bottom: 15px;
	padding: 22px;
}
.dataq_content h5 {
	color: #424B54;
	font-size: 13px;
	font-weight: 700;
	margin-bottom: 15px;
}
.dataq_content h4 {
	color: #00ACEF;
	font-size: 24px;
	font-weight: 400;
}
