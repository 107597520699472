.tophead_container {
	padding: 30px 40px 20px 30px;
}

.top_header {
	display: flex;
	align-items: center;
}

.top_navmenus .nav_ul {
	display: flex;
	margin-left: 35px;
	align-items: center;
	color: #424B54;
}

.nav_ul>li+li {
	margin-left: 35px;
}

.nav_ul li a {
	position: relative;
}

.nav_ul li a.active {
	color: #00ACEF;
}

.nav_ul i {
	margin-right: 7px;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	top: -2px;
	width: 15px;
}

.nav_ul li a.active i {
	color: #FFCA3A;
}

.nav_ul li:hover {
	color: #0090C7;
}

.nav_ul li:hover i {
	color: #FFCA3A;
}

.main_logo {
	width: 64px;
}

.main_logo img {
	width: 100%;
	object-fit: cover;
	object-position: center;
}

.user_section {
	margin-left: auto;
	margin-right: 2em;
}

.user_logo i {
	margin-right: 10px;
}
.user_logo .fas {
	margin-left: 5px;
}
