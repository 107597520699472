.toggle_btn.activated {
  background: #00acef;
  color: white;
}
.toggle_btn:focus {
  outline: none;
}
.toggle_btn::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 10px 0 10px;
  border-color: #00acef transparent transparent transparent;
  position: absolute;
  bottom: -11px;
  left: calc(50% - 5px);
  display: none;
}
.toggle_btn {
  display: inline-block;
  height: 42px;
  background: #939393;
  color: #fff !important;
  border-color: rgba(0, 255, 154, 0);
  border-style: solid;
  padding: 0 20px;
  border-radius: 42px;
  text-align: center;
  position: relative;
}
.toggle_btn.activated::after {
  display: block;
}
.toggle_btn {
  margin-bottom: 20px;
  max-width: 400px;
}
.grey_toggle_btn {
  background: #939393;
}
.toggle_btn + .toggle_btn {
  margin-left: 10px;
}
