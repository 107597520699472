.view_container {
    max-width: 1140px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}
.view_container h2 {
    font-size: 18px;
    color: #004560;
    font-weight: 700;
}
.forecastCard{
    margin-top: 10px
}
.view-collection{
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
    visibility: hidden;
}