.navbar-empty {
    justify-content: center;
    height: 40px;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
}
.center-navbar-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 30px;
    padding-top:5px;
}
.login-page-container{
    padding: 65px 15px;
    max-width: 960px;
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
}
.login-page-content{
    margin: 0;
    box-sizing: border-box;
}
.login-page-left{
    font-size: 18px;
    line-height: 1.5;
    max-width: 58%;
}
.login-page-left > p {
    margin-top: 0;
    margin-bottom: 1rem;
}
.login-page-left > ul > a > li {
    font-weight: 600;
}
.login-page-right{
    width: 42%;
}
.login-page-right > Card{
    min-width: 100%;
}
.login-page-right > Card > Form{
    min-width: 100%;
}
.submit-login{
    margin-top: 16px;
    width: 100%;
}
.remember-me-login{
    margin-left: 1em;
    margin-right: auto;
}
.forgot-password{
    margin-left: auto;
    margin-right: 1em;
}
.login-register-now{
    margin-top: 1em;
}