.pages-container{
	text-align: center;
		margin-left: auto !important;
	margin-right: auto !important;
}
.pages {
	width: 100%;
	text-align: center;
	margin-left: auto !important;
	margin-right: auto !important;
}