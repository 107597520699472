.runforcastform_secttion {
    background: #004560;
}
.forecast_container, .donor_container, .newforecast_container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 40px 0;
}
.forecast_container > h2 {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}
.donor_container > h2 {
    font-size: 18px;
}
.attr_section {
    padding-top:10px
}
.attr_section > h2 {
    font-size: 18px;
}
.fform_wrapper {
    display: flex;
}
.fleft_form {
    flex: 0 0 29%;
    max-width: 29%;
    padding-right: 40px;
}
.fleft_tform {
    margin-bottom: 15px;
}
.form-group {
    padding-bottom: 8px;
}
.fleft_form .form-group {
    width: 100%;
}
.form-group label {
    line-height: 1.8;
    font-weight: 400;
}
.selectdiv {
    background: #fff;
    border-radius: 5px;
    position: relative;
}
.fleft_tform .selectdiv::before {
    line-height: 40px;
}
.selectdiv::before {
    content: "\f078";
    position: absolute;
    line-height: 32px;
    right: 7px;
    font-size: 10px;
    z-index: -1;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    font-weight: 900;
}
.fleft_tform .selectdiv select {
    height: 40px;
}
.selectdiv select {
    width: 100%;
    border-radius: 5px;
    padding: 0 18px 0 8px;
    border: none;
    height: 32px;
    -moz-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    outline: none;
    font-size: 13px;
    font-weight: 400;
}
.selectdiv select option {
    color: #939393;
}
.forecast_container, .donor_container, .newforecast_container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 40px 0;
}
.forecast_label {
    color: #fff;
    display: block;
    margin-bottom: 7px;
}
.f_label, .clear_link {
    color: #fff;
    opacity: 0.3;
    font-size: 13px;
}
.yellow_btn {
    background: #FFCA3A;
    width: 80%;
    margin-right: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    color: #000;
}
.clear_link {
    display: block;
    text-align: center;
}
.frightt_form {
    display: flex;
    flex-wrap: wrap;
    border-left: 1px solid #fff;
    padding-left: 40px;
}
.frightt_form .form-group {
    width: 33.33%;
}
.input_div {
    margin-bottom: 8px;
}
.input_div input {
    border-radius: 5px;
    padding: 0 20px;
    border: none;
    height: 40px;
}
.input_div div {

}
.rdt {
    border-radius: 5px;
    padding: 0 30px 0 0;
    border: none;
    height: 40px;
}
.donor_container h2 {
    color: #004560;
    font-weight: 400;
    margin-bottom: 20px;
}
.donor_content {
    display: flex;
    justify-content: space-between;
}

.equation.cell {
    position: relative;
}
.error.cell {
    background: rgba(255,0,0,0.14);
    font-size: 0.8em;
    color: red;
}
.error.cell > div.text {
    text-align: center;
}
.equation.cell:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    border-style: solid;
    border-width: 6px 6px 0 0;
    border-color: #2185d0 transparent transparent transparent;
    z-index: 2;
}

.row-handle.cell {
    width: 1rem;
}

tbody .row-handle.cell, thead .cell:not(.row-handle) {
    cursor: move;
}

.data-grid-container table.data-grid tr {
    background: white;
}
.data-grid-container table.data-grid .drop-target,  .data-grid-container table.data-grid thead .cell.read-only.drop-target {
    background: #6F86FC;
    transition: none;
    color: white;
}
.data-grid-container table.data-grid thead .cell.read-only {
    transition: none;
}

table.data-grid {
    margin: auto;
    width: 100%;
}
.Select-control, .Select-input, .Select-placeholder,.Select-clear, .Select-placeholder, .Select--single > .Select-control .Select-value {
    height: 15px;
    line-height: 12px;
    font-size: 12px;
    text-align: left;
    border-radius: 0;
    border: 0;
}

.Select-control input{
    font-size: 12px;
    padding: 0;
}
.sheet-container {
    display: block;
    padding: 5px;
    box-shadow: 0px 0px 6px #CCC;
    margin: auto;
    width: 500px;
    margin-top: 20px;
    transition: box-shadow 0.5s ease-in;
}
.sheet-container:hover {
    transition: box-shadow 0.5s ease-in;
    box-shadow: 0px 0px 1px #CCC;
}
.sheet-container table.data-grid tr td.cell:not(.selected){
    border: 10px solid #ececec;
}
.sheet-container table.data-grid tr td.cell, .sheet-container table.data-grid tr th.cell {
    font-size: 12px;
}
.data-grid-container .data-grid .cell .value-viewer, .data-grid-container .data-grid .cell .data-editor{
    height:2em;
}
.attr_section h2 {
    margin-bottom: 20px;
}
.donor_container h2 {
    color: #004560;
    font-weight: 400;
    margin-bottom: 20px;
}

.forecast-new-check-input{
    margin-top: 0 !important;
    margin-left: 0 !important;
    position: inherit !important;
}