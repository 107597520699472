.decline-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  height: 85%;
}
.decline-left-section {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10px;
}
.decline-right-section {
  display: inline;
  width: 50%;
  height: 100%;
}
.decline-inner-chart {
  width: 100%;
  height: 80%;
  margin-bottom: 10px;
  background-color: white;
}
.table-button-row {
  height: 8%;
  margin-bottom: 2%;
}
.decline-inner-table {
  height: 89%;
  min-height: 89%;
  max-height: 89%;
  overflow: auto;
}
.decline-pages {
  height: 10%;
}
.chart-section {
  height: 50%;
  margin-bottom: 4%;
}
