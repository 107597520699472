.register-parent {
    align-items: center;
    box-sizing: border-box;
    color: rgb(33, 37, 41);
    display: flex;
    height: 100vh;
    width: 100vw;
}

.register-card {
    margin: 0 auto; /* Added */
    float: none; /* Added */
    min-width: 20%;
}

.error-msg {
    color: red;
}

.register-msg{
    color: black;
}
