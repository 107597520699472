.kpi_container_ {
	flex: 1 0 0;
	padding-left: 10px;
	padding-right: 10px;
	height: 152.5px !important;
	width: 50% !important;
}

.kpi_container_content {
	display: flex;
	background-color: #fff;
	text-align: center;
	height: 100%;
}

.kpi_container_part {
	padding: 20px 20px 0;
	width: 100%;
}

.kpi_text>p {
	color: #56D9FE;
	font-size: 24px;
}

.kpi_text>h5 {
	color: #4D4F5C;
	font-size: 13px;
	margin-top: 6px;
	margin-bottom: 10px;
}

.kpi_bottom_part {
	padding: 8px 10px;
	text-align: center;
	border-top: 1px solid #ddd;
	font-size: 12px;
	width: 100%;
	color: #24ae68;
}

.kpi_text.negative_view>p, .kpi_bottom_part.negative_view {
	color: #FF6565;
}

.kpi_text.no_data_view>p {
	color: #004560;
}

.kpi_bottom_part.no_data_view {
	visibility: hidden;
}

.kpi_bottom_part.default {
	color: #004560;
}

.figure-text{
	margin-bottom: 0.5em;
}