.loading-frame {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: #7f7f7f;
    background: rgba(58, 58, 58, 0.5);
}

.spinner-frame{
    padding-top:calc(50vh - 7.5rem);
    padding-left: calc(50vw - 7.5rem);
}