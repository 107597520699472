.bothead_container {
	background-color: #0090C7;
	color: #fff;
}

.secondary_menus {
    margin: 0 auto;
}

.sec_ul {
	display: flex;
	padding-left: 119px;
}

.sec_ul a {
	font-size: 13px;
	padding: 14px 22px;
	display: block;
}

.sec_ul .active {
	background-color: #39A9D4;
}
