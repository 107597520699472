a.policy {
  color: #2980d9;
}

h1.policy {
  font-size: 3em;
  text-align: center;
}

h2.policy {
  font-size: 2.5em;
  margin-block-end: 0.25em;
  margin-left: 1em;
}

h3.policy {
  font-size: 2em;
  margin-block-end: 0.25em;
  margin-left: 2em;
}

ul.policy {
  margin-block-end: 10em;
}

li.policy {
  font-size: 1.25em;
  margin-block-end: 0.25em;
  margin-left: 3em;
  margin-right: 2em;
}

p.policy {
  font-size: 1.25em;
  margin-block-end: 0.5em;
  margin-left: 5em;
  margin-right: 5em;
}
