.container {
    background-color: rgb(240, 239, 236);
    max-height: 100vh;
    max-width: 100vw;
    bottom: 0;
    box-sizing: border-box;
    color: rgb(33, 37, 41);
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 10.8667px;
    font-weight: 400;
    line-height: 16.3px;
    right: 0;
    text-align: left;
    top: 0;
    overflow: hidden; /* Hide scrollbars */
}

.login-parent {
    align-items: center;
    box-sizing: border-box;
    color: rgb(33, 37, 41);
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden; /* Hide scrollbars */
}

.login-card {
    margin: 0 auto; /* Added */
    float: none; /* Added */
    min-width: 20%;
}

.error-msg {
    color: red;
}

.register-msg {
    color: black;
}

.black-label {
    color: black !important;
}

.login-card-body {
    min-width: 20%;
}