.hall_row {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  flex-direction: row;
  margin-bottom: 15px;
}
.hall_data_section {
  flex: 0 0 49%;
  max-width: 49%;
  max-height: 80%;
  padding-left: 10px;
  padding-right: 10px;
}
.hall_data_section.flex {
  display: flex;
  flex-direction: column;
}
.hall_data_section.cards {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.hall_chart {
  flex-grow: 1;
  background-color: white;
}
.hall_data_section h3 {
  color: #004560;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 10px;
}
.hall_graph {
  background: #fff;
  flex: 0 0 calc(55% - 10px);
  max-width: calc(55% - 10px);
}
.data_half {
  width: 49%;
}
.hall_data_section h3 {
  color: #004560;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 10px;
}
.hall_data_content {
  padding: 2px;
  margin-bottom: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
}
.hall_data_content h4 {
  color: #424b54;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 13px;
}
.hall_data_content h5 {
  color: #00acef;
  font-size: 24px;
  font-weight: 400;
}
.data-section {
  width: 33%;
}
.data-section.margin {
  margin-right: 20px;
}
.data_half_t .data_half + .data_half {
  margin-left: 15px;
}
.hall-donor-journey-chart {
  flex-grow: 1;
}
.hall-donor-journey-inner {
  width: 100%;
  height: 305px;
  background-color: white;
}
.hall-donor-journey-chart > h3 {
  color: #004560;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 10px;
}

.hall-donor-journey-heading {
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: flex-end;
}

.hall-donor-journey-heading .download_spreadsheet {
  margin-bottom: 10px;
}
