.ac_graph_section {
    width: 100%;
}

.ac_chart_section {
    height: 500px;
    background-color: white;
}

.row1 {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 20px;
    margin-bottom: 1em;
    justify-content: flex-start;
}