.context-filters {
  display: flex;
  justify-content: flex-start;
}

.context-filters > .filters-row {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: flex-start;
  flex-grow: 1;
}

.context-filters > .filters-row > div {
  overflow: hidden;
}

.context-filters > .more-filters {
  white-space: nowrap;
  align-self: start;
}
