.card-loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(58, 58, 58, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
