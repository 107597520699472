.baseline-forecast-container {
    display: flex;
    justify-content: center;
}

.baseline-forecast-inner {
    width: 80%;
}

.baseline-forecast-inner>h2 {
    margin: 10px 0 10px 0;
    padding-top: 40px;
}

.baseline-forecast-row {
    display: flex;
    height: 350px;
}

.baseline-forecast-column {
    min-width: 0;
    flex: 0 0 calc(50% - 10px);
    display: flex;
    flex-direction: column;
}

.baseline-forecast-column.first {
    margin-right: 20px;
}

h3.baseline-forecast-title {
    margin: 30px 0;
    color: #004560;
    font-weight: 400;
    font-size: 18px;
}


.baseline-chart-container {
    background-color: white;
    flex-grow: 1;
}