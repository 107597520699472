.vi_start {
    flex: 0 0 25%;
    max-width: 24%;
    margin-left: 0.5%;
    justify-content: space-between;
    margin-right: 0.5%;
}
.vieach_box {
    background-color: #fff;
    margin: 10px 0;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}
.vi_content {
    display: flex;
    align-items: center;
    padding: 15px;
    width: 100%;
    justify-content: space-between;
}
.vi_logo {
    border: 1px solid #56D9FE;
    padding: 20px;
    border-radius: 100%;
    height: 71px;
    width: 71px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 0 0 71px;
}
.vi_logo img {
    object-fit: cover;
    object-position: center;
}
.vi_content .vi_ltext {
    flex: 0 0 calc(100% - 80px);
}
.vi_ltext > h3 {
    color: #004560;
    font-size: 16px;
    font-weight: 700;
}
.vi_ltext > h2 {
    color: #56D9FE;
    font-size: 35px;
    margin-top: 5px;
}
.vi_botcontent {
    display: block;
    padding: 8px 10px;
    text-align: center;
    border-top: 1px solid #ddd;
    font-size: 12px;
    color: #004560;
    width: 100%;
    flex: 0 0 100%;
    align-self: flex-end;
}
.vi_logo span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 8px;
    text-align: center;
}